
import { defineComponent } from "vue";
import UserModule from "../store/modules/User/user";

export default defineComponent({
  setup () {
    const timeout = UserModule.getUserSettings?.sessionSettings?.expirationTimeout;
    return { timeout };
  },
});
